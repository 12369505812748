import {HttpHeaders} from '@angular/common/http'
import {preventBgoProprietaryHttpInterceptorHeader} from '../http/prevent.interceptor'
import {ApolloLink} from '@apollo/client/core'
import {HttpLink} from 'apollo-angular/http'
import {ConfigService} from '../config/config.service'
import {print, stripIgnoredCharacters} from 'graphql'
import {CMS_GRAPHQL_CLIENT_NAME, MESSAGING_GRAPHQL_CLIENT_NAME} from '../content/content-graphql-split'
import {setContext} from '@apollo/client/link/context'
import {Injectable} from '@angular/core'
import {Logger} from '../logging/logger.types'

@Injectable({providedIn: 'root'})
export class GraphqlLinkFactory {
  // we lazy load the messaging api, because it comes with some extra deps for websockets that take up a lot of bundle space
  // this prop serves as cache for the messaging link to avoid re-creating it when auth status changes for instance
  messagingLink?: ApolloLink

  constructor(
    private readonly configService: ConfigService,
    private readonly httpLink: HttpLink,
    private readonly logger: Logger,
  ) {}

  build(getJwtToken?: () => Promise<string | undefined>): ApolloLink {
    const auth = this.authLink(getJwtToken)
    return this._build(auth, this.messagingLink)
  }

  async buildWithMessagingWebsockets(getJwtToken?: () => Promise<string | undefined>): Promise<ApolloLink> {
    const auth = this.authLink(getJwtToken)
    this.messagingLink = await this.messagingApiLink(auth, getJwtToken)
    return this._build(auth, this.messagingLink)
  }

  private _build(auth?: ApolloLink, messagingApi?: ApolloLink): ApolloLink {
    const datoCmsApi = this.datoCmsApiLink()
    const coreApi = this.coreGraphqlApiLink(auth)

    // the messaging API with real time capabilities is separated from the core API
    // split traffic to our backend API between the messaging endpoint (with websockets) and the core api
    const buildigoApi = messagingApi
      ? ApolloLink.split(
          op => ![MESSAGING_GRAPHQL_CLIENT_NAME].includes(op.getContext().clientName),
          coreApi,
          messagingApi,
        )
      : coreApi

    // split traffic between CMS and our backend API depending on the client name
    return ApolloLink.split(
      op => [CMS_GRAPHQL_CLIENT_NAME].includes(op.getContext().clientName),
      datoCmsApi,
      buildigoApi,
    )
  }

  private authLink(getJwtToken?: () => Promise<string | undefined>) {
    if (!getJwtToken) {
      return undefined
    }
    return setContext(() => withAuthHeaders(getJwtToken))
  }

  private coreGraphqlApiLink(authLink?: ApolloLink): ApolloLink {
    const buildigoApi = this.httpLink.create({
      uri: this.configService.config.apiUrl,
      headers: new HttpHeaders({
        // manually set these headers for this link, because other links may not accept these headers in their CORS policy
        'apollographql-client-name': 'bgo-web-ui',
        'apollographql-client-version': this.configService.config.version,
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
    let buildigoLink: ApolloLink[] = [buildigoApi]
    if (authLink) {
      buildigoLink = [authLink, buildigoApi]
    }

    return ApolloLink.from(buildigoLink)
  }

  private messagingApiLink(
    authLink?: ApolloLink,
    getJwtToken?: () => Promise<string | undefined>,
  ): Promise<ApolloLink | undefined> {
    // TODO: fix bundle size issue or use polling
    this.logger.warn(`Subscriptions are disabled for now because it requires a lot of extra bundle size`)
    return Promise.resolve(undefined)
    // buildigo auth
    // if (!authLink || !getJwtToken) {
    //   return undefined
    // }
    // const messaging = this.configService.config.messaging
    // if (!messaging) {
    //   this.logger.warn(`No messaging config found!`)
    //   return undefined
    // }
    //
    // const url = messaging.apiUrl
    // const region = messaging.region
    //
    // const auth = {
    //   type: 'AMAZON_COGNITO_USER_POOLS' as const,
    //   jwtToken: () => getJwtToken().then(token => token || ''),
    // }
    //
    // // lazy load the websocket lib to save bundle size
    // const {
    //   // types don't match prod build, where we have a default object wrapping everything
    //   default: {createSubscriptionHandshakeLink},
    // } = await import('aws-appsync-subscription-link')
    //
    // return ApolloLink.from([
    //   // buildigo auth
    //   authLink,
    //   // appsync subscription
    //   createSubscriptionHandshakeLink({url, region, auth}, this.httpLink.create({uri: url})),
    // ])
  }

  private datoCmsApiLink() {
    const {apiToken, preview, environment} = this.configService.config.datocms
    return this.httpLink.create({
      uri: `https://graphql.datocms.com/`,
      headers: new HttpHeaders({
        ...preventBgoProprietaryHttpInterceptorHeader,
        Authorization: `Bearer ${apiToken}`,
        ...(preview ? {'X-Include-Drafts': 'true'} : undefined),
        ...(environment ? {'X-Environment': environment} : undefined),
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      // this helps with APIs query size limit
      // https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-size-limits
      // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
  }
}

export async function withAuthHeaders(getJwtToken: () => Promise<string | undefined>) {
  const bearerToken = await getJwtToken()
  if (bearerToken) {
    return {headers: {Authorization: 'Bearer ' + bearerToken}}
  } else {
    return {}
  }
}
